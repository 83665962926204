<template>
  <div>
    <div class="title">
      <p>{{ mc }}</p>
    </div>
    <div class="con-detail">
      <history-card :listData="dataTab" :tabId="tabId" :zrzyDyId="zrzyDyId"></history-card>
      <not-found v-if="dataTab.length === 0"></not-found>
      <div class="pagination" v-if="dataTab.length > 0">
        <el-pagination
            :current-page="start"
            :page-size="size"
            :page-sizes="[12, 24, 36, 48]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryCard from "@/views/kpzsInfor/components/historyCard";
import {gettabData} from "@/api/homeService";
import notFound from "../../../components/notFound";

export default {
  name: "ListShow",
  components: {HistoryCard,notFound},
  props: {
    tabId: {
      type: String,
      default: () => ''
    },
    mc:{
      type: String,
      default: () => ''
    },
    zrzyDyId: {
      type: String,
      default: () => '8e6159f4-5b68-4202-866d-758d2ceeb8d6'
    },
  },
  data() {
    return {
      start: 1,
      size: 12,
      total: 100,
      dataTab: [],
    }
  },
  created() {
    this.getDyxxById()
  },
  mounted() {
  },
  watch: {
    'tabId': {
      handler(val) {
        this.getDyxxById();
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getDyxxById()
    },
    handleCurrentChange(val) {
      this.start = val;
      this.getDyxxById()
    },
    getDyxxById() {
      const zrzyDyId = this.$route.query.zrzyDyId ? this.$route.query.zrzyDyId : this.zrzyDyId;
      const tabId = this.$route.query.tabId ? this.$route.query.tabId : this.tabId;
      const dy = {
        'zrzyid': zrzyDyId,
        'id': tabId,
        "start": this.start,
        "size": this.size
      }
      gettabData(dy).then(res => {
        if (res.code === '0') {
          this.dataTab = res.data;
          this.total = res.total
          if (this.dataTab.length > 0) {
            this.mc = this.dataTab[0].mc
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
