<template>
  <div class="history-card">
    <el-row :gutter="30">
      <el-col v-for="(item, index) of lists" :key="index" :span="6">
        <div class="list" @click="todetail(item)">
          <img :src="item.ydlj==null?item.bdlj:item.ydlj">
          <div class="list-item">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="p-ms">
              <p>{{ item.con }}</p>
            </div>
            <div class="icon-right">
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>
<script>


export default {
  components: {},
  props: {
    listData: {
      type: Array
    },
    tabId: {
      type: String,
      default: () => ''
    }, zrzyDyId: {
      type: String,
      default: () => '8e6159f4-5b68-4202-866d-758d2ceeb8d6'
    },
  },
  watch: {
    'listData': {
      handler(val) {
        if (val != undefined) {
          this.lists = val
          for (let i = 0; i < this.lists.length; i++) {
            if (this.lists[i].dtlj != null) {
              var newImg = new Image()
              newImg.src = this.lists[i].dtlj
              if (newImg.complete) {
                newImg.onload = () => { // 图片加载成功后把地址给原来的img
                  console.log('加载图片')
                }
              }
            }
          }
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      lists: this.listData,
    }
  },
  mounted() {
    this.$route.meta.title = this.$route.query.cd
  },
  methods: {
    todetail(val) {
      let path = this.$route.path.split('/')
      console.log(path)
      let pathUrl = path[path.length - 1];
      console.log(pathUrl+ 'Detail')
      let tabId = this.$route.query.tabId ? this.$route.query.tabId : this.tabId;
      let zrzyDyId = this.$route.query.zrzyDyId ? this.$route.query.zrzyDyId : this.zrzyDyId;
      this.$router.push({
        name: 'lvtyInforDetail',
        query: {
          id: val.sjid,
          tabId: tabId,
          zrzyDyId: zrzyDyId
        }
      })

    }
  }
}
</script>

<style lang="less">
.history-card {
  .list {
    width: 260px;
    height: 360px;
    border-radius: 4px 4px 0px 0px;
    position: relative;
    margin-bottom: 30px;
    background: rgba(173, 202, 217, 0.2);

    img {
      width: 100%;
      height: 100%;
    }

    .list-item {
      position: absolute;
      bottom: 0px;
      width: 260px;
      // height: 120px;
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 10px 12px 20px;
      box-sizing: border-box;

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 25px;
        padding: 8px 0px;
        height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .p-ms, p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .icon-right {
        width: 100%;
        display: flex;
        margin-top: 5px;

        .el-icon-right {
          line-height: 28px;
          text-align: center;
          color: #FFFFFF;
          font-size: 16px;
          display: block;
          margin-left: auto;
          width: 28px;
          height: 28px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #FFFFFF;
          border-radius: 4px;
        }
      }
    }
  }

}
</style>

